import type { GetServerSidePropsCallback } from "@/utils/apis/layout-props";
import type { GetServerSidePropsContext, NextPage } from "next";
import Head from "next/head";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { ErrorPageContent } from "../components/ErrorPage/ErrorPage";
import type { XXLAppData } from "../global";
import { withPageData } from "../utils/common-page-data/common-page-data";
import { getFullPageTitle } from "../utils/page-helper";

type ErrorPageProps = {
  siteId: string;
};

const ErrorPage: NextPage<XXLAppData | { statusCode?: number }> = (context) => {
  if (!("environmentData" in context)) {
    return <>Internal Server Error</>;
  }
  const { t } = useTranslations();
  const pageTitle = getFullPageTitle(t("errors.not.found.title"), t);

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta name="robots" content={"noindex"} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:type" content="website" />
      </Head>
      <ErrorPageContent />
    </>
  );
};

export const getErrorPageProps: GetServerSidePropsCallback<
  ErrorPageProps & XXLAppData
> = async (_context, props) =>
  Promise.resolve({
    props: { ...props, siteId: props.environmentData.siteUid },
  });

export default ErrorPage;

export const getServerSideProps = (context: GetServerSidePropsContext) => {
  if (context.res.statusCode !== 404) {
    return { props: { statusCode: context.res.statusCode } };
  }
  return withPageData(getErrorPageProps)(context);
};
